
let viewgird = [
    {
        path: '/Sys_Log',
        name: 'sys_Log',
        component: () => import('@/views/system/Sys_Log.vue'),
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/Sys_User',
        name: 'Sys_User',
        component: () => import('@/views/system/Sys_User.vue'),
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/permission',
        name: 'permission',
        component: () => import('@/views/system/Permission.vue'),
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/Sys_Dictionary',
        name: 'Sys_Dictionary',
        component: () => import('@/views/system/Sys_Dictionary.vue'),
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/Sys_Role',
        name: 'Sys_Role',
        component: () => import('@/views/system/Sys_Role.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_Role1',
        name: 'Sys_Role1',
        component: () => import('@/views/system/Sys_Role1.vue'),
        meta: {
            keepAlive: false
        }
    }
    ,
    //  {
    //     path: '/Sys_DictionaryList',
    //     name: 'Sys_DictionaryList',
    //     component: () => import('@/views/system/system/Sys_DictionaryList.vue'),
    //     meta: {
    //         keepAlive: false
    //     }
    // }, 
    {
        path: '/FormDesignOptions',
        name: 'FormDesignOptions',
        component: () => import('@/views/system/form/FormDesignOptions.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/FormCollectionObject',
        name: 'FormCollectionObject',
        component: () => import('@/views/system/form/FormCollectionObject.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Custom/Sys_User_ExtendData',
        name: 'Sys_User_ExtendData',
        component: () => import('@/views/custom/custom/Sys_User_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_Table_Extend',
        name: 'Sys_Table_Extend',
        component: () => import('@/views/custom/custom/Sys_Table_Extend.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_User_Extend',
        name: 'Sys_User_Extend',
        component: () => import('@/views/custom/custom/Sys_User_Extend.vue'),
        meta: {
            keepAlive: false
        }
    }
    , {
        path: '/Base_DefectItem_Extend',
        name: 'Base_DefectItem_Extend',
        component: () => import('@/views/custom/custom/Base_DefectItem_Extend.vue'),
        meta: {
            keepAlive: false
        }
    }
    , {
        path: '/Base_MeritPay_Extend',
        name: 'Base_MeritPay_Extend',
        component: () => import('@/views/custom/custom/Base_MeritPay_Extend.vue'),
        meta: {
            keepAlive: false
        }
    }
    , {
        path: '/Base_Process_Extend',
        name: 'Base_Process_Extend',
        component: () => import('@/views/custom/custom/Base_Process_Extend.vue'),
        meta: {
            keepAlive: false
        }
    }
    , {
        path: '/Base_Product_Extend',
        name: 'Base_Product_Extend',
        component: () => import('@/views/custom/custom/Base_Product_Extend.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_User_ExtendData',
        name: 'Sys_User_ExtendData',
        component: () => import('@/views/custom/custom/Sys_User_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_Dept',
        name: 'Sys_Dept',
        component: () => import('@/views/system/Sys_Dept.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_Unit',
        name: 'Sys_Unit',
        component: () => import('@/views/custom/custom/Sys_Unit.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_DefectItem',
        name: 'Base_DefectItem',
        component: () => import('@/views/custom/custom/Base_DefectItem.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Process',
        name: 'Base_Process',
        component: () => import('@/views/custom/custom/Base_Process.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_ProcessList',
        name: 'Base_ProcessList',
        component: () => import('@/views/custom/custom/Base_ProcessList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_NumberRule',
        name: 'Base_NumberRule',
        component: () => import('@/views/custom/custom/Base_NumberRule.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_ProcessLine',
        name: 'Base_ProcessLine',
        component: () => import('@/views/custom/custom/Base_ProcessLine.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_ProcessLineList',
        name: 'Base_ProcessLineList',
        component: () => import('@/views/custom/custom/Base_ProcessLineList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_MeritPay',
        name: 'Base_MeritPay',
        component: () => import('@/views/custom/custom/Base_MeritPay.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Product',
        name: 'Base_Product',
        component: () => import('@/views/custom/custom/Base_Product.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_ProductFlow',
        name: 'Base_ProductFlow',
        component: () => import('@/views/custom/custom/Base_ProductFlow.vue'),
        meta: {
            keepAlive: false
        }      
    }, {
        path: '/View_Base_MaterialDetail',
        name: 'View_Base_MaterialDetail',
        component: () => import('@/views/custom/custom/View_Base_MaterialDetail.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Ware_InWareHouseBill',
        name: 'Ware_InWareHouseBill',
        component: () => import('@/views/warehouse/warehouse/Ware_InWareHouseBill.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Ware_InWareHouseBillList',
        name: 'Ware_InWareHouseBillList',
        component: () => import('@/views/warehouse/warehouse/Ware_InWareHouseBillList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Ware_InPurchaseBill',
        name: 'Ware_InPurchaseBill',
        component: () => import('@/views/warehouse/warehouse/Ware_InPurchaseBill.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Ware_InProductionBill',
        name: 'Ware_InProductionBill',
        component: () => import('@/views/warehouse/warehouse/Ware_InProductionBill.vue'),
        meta: {
            keepAlive: false
        }  
    }, {
        path: '/Ware_InSalesReturnBill',
        name: 'Ware_InSalesReturnBill',
        component: () => import('@/views/warehouse/warehouse/Ware_InSalesReturnBill.vue'),
        meta: {
            keepAlive: false
        }   
    }, {
        path: '/Ware_InProductionReturnBill',
        name: 'Ware_InProductionReturnBill',
        component: () => import('@/views/warehouse/warehouse/Ware_InProductionReturnBill.vue'),
        meta: {
            keepAlive: false
        }                           
    }, {
        path: '/Ware_OutWareHouseBillList',
        name: 'Ware_OutWareHouseBillList',
        component: () => import('@/views/warehouse/warehouse/Ware_OutWareHouseBillList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Ware_OutDeptBill',
        name: 'Ware_OutDeptBill',
        component: () => import('@/views/warehouse/warehouse/Ware_OutDeptBill.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Ware_OutSalesBill',
        name: 'Ware_OutSalesBill',
        component: () => import('@/views/warehouse/warehouse/Ware_OutSalesBill.vue'),
        meta: {
            keepAlive: false
        }        
    }, {
        path: '/Ware_OutPurchaseReturnBill',
        name: 'Ware_OutPurchaseReturnBill',
        component: () => import('@/views/warehouse/warehouse/Ware_OutPurchaseReturnBill.vue'),
        meta: {
            keepAlive: false
        }   
    }, {
        path: '/Ware_OutWareHouseBill',
        name: 'Ware_OutWareHouseBill',
        component: () => import('@/views/warehouse/warehouse/Ware_OutWareHouseBill.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/View_WareInOutDetail',
        name: 'View_WareInOutDetail',
        component: () => import('@/views/warehouse/warehouse/View_WareInOutDetail.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/View_StockBalance',
        name: 'View_StockBalance',
        component: () => import('@/views/warehouse/warehouse/View_StockBalance.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ProductPlanList',
        name: 'Production_ProductPlanList',
        component: () => import('@/views/production/production/Production_ProductPlanList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ProductPlan',
        name: 'Production_ProductPlan',
        component: () => import('@/views/production/production/Production_ProductPlan.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_AssembleWorkOrderList',
        name: 'Production_AssembleWorkOrderList',
        component: () => import('@/views/production/production/Production_AssembleWorkOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_AssembleWorkOrder',
        name: 'Production_AssembleWorkOrder',
        component: () => import('@/views/production/production/Production_AssembleWorkOrder.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_WorkOrder',
        name: 'Production_WorkOrder',
        component: () => import('@/views/production/production/Production_WorkOrder.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_WorkOrderList',
        name: 'Production_WorkOrderList',
        component: () => import('@/views/production/production/Production_WorkOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_PrintCatalog',
        name: 'Base_PrintCatalog',
        component: () => import('@/views/custom/custom/Base_PrintCatalog.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_PrintTemplate',
        name: 'Base_PrintTemplate',
        component: () => import('@/views/custom/custom/Base_PrintTemplate.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_PrintTemplateTree',
        name: 'Base_PrintTemplateTree',
        component: () => import('@/views/custom/custom/Base_PrintTemplateTree.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ReportWorkOrder',
        name: 'Production_ReportWorkOrder',
        component: () => import('@/views/production/production/Production_ReportWorkOrder.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ReportWorkOrderTree',
        name: 'Production_ReportWorkOrderTree',
        component: () => import('@/views/production/production/Production_ReportWorkOrderTree.vue'),
        meta: {
            keepAlive: false
        }        
    }, {
        path: '/Production_ReportWorkOrderList',
        name: 'Production_ReportWorkOrderList',
        component: () => import('@/views/production/production/Production_ReportWorkOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/View_OutputStatistics',
        name: 'View_OutputStatistics',
        component: () => import('@/views/report/report/View_OutputStatistics.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_WorkFlowTableStep',
        name: 'Sys_WorkFlowTableStep',
        component: () => import('@/views/system/flow/Sys_WorkFlowTableStep.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_WorkFlowStep',
        name: 'Sys_WorkFlowStep',
        component: () => import('@/views/system/flow/Sys_WorkFlowStep.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_WorkFlowTable',
        name: 'Sys_WorkFlowTable',
        component: () => import('@/views/system/flow/Sys_WorkFlowTable.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_WorkFlow',
        name: 'Sys_WorkFlow',
        component: () => import('@/views/system/flow/Sys_WorkFlow.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ProcessFlowTableStep',
        name: 'Production_ProcessFlowTableStep',
        component: () => import('@/views/production/processflow/Production_ProcessFlowTableStep.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ProcessFlowStep',
        name: 'Production_ProcessFlowStep',
        component: () => import('@/views/production/processflow/Production_ProcessFlowStep.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ProcessFlowTable',
        name: 'Production_ProcessFlowTable',
        component: () => import('@/views/production/processflow/Production_ProcessFlowTable.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_ProcessFlow',
        name: 'Production_ProcessFlow',
        component: () => import('@/views/production/processflow/Production_ProcessFlow.vue'),
        meta: {
            keepAlive: false
        }        
    }, {
        path: '/View_EmployeePerformance',
        name: 'View_EmployeePerformance',
        component: () => import('@/views/report/report/View_EmployeePerformance.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/View_SalaryReport',
        name: 'View_SalaryReport',
        component: () => import('@/views/report/report/View_SalaryReport.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/View_DefectItemDistribute',
        name: 'View_DefectItemDistribute',
        component: () => import('@/views/report/report/View_DefectItemDistribute.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/View_DefectItemSummary',
        name: 'View_DefectItemSummary',
        component: () => import('@/views/report/report/View_DefectItemSummary.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/View_ProductionReport',
        name: 'View_ProductionReport',
        component: () => import('@/views/report/report/View_ProductionReport.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Notice',
        name: 'Base_Notice',
        component: () => import('@/views/custom/custom/Base_Notice.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Product_ExtendData',
        name: 'Base_Product_ExtendData',
        component: () => import('@/views/custom/custom/Base_Product_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Process_ExtendData',
        name: 'Base_Process_ExtendData',
        component: () => import('@/views/custom/custom/Base_Process_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_DefectItem_ExtendData',
        name: 'Base_DefectItem_ExtendData',
        component: () => import('@/views/custom/custom/Base_DefectItem_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_MaterialDetail_ExtendData',
        name: 'Base_MaterialDetail_ExtendData',
        component: () => import('@/views/custom/custom/Base_MaterialDetail_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_MeritPay_ExtendData',
        name: 'Base_MeritPay_ExtendData',
        component: () => import('@/views/custom/custom/Base_MeritPay_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_QuartzLog',
        name: 'Sys_QuartzLog',
        component: () => import('@/views/system/quartz/Sys_QuartzLog.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_QuartzOptions',
        name: 'Sys_QuartzOptions',
        component: () => import('@/views/system/quartz/Sys_QuartzOptions.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_VersionInfo',
        name: 'Sys_VersionInfo',
        component: () => import('@/views/system/system/Sys_VersionInfo.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sys_UserTree',
        name: 'Sys_UserTree',
        component: () => import('@/views/system/Sys_UserTree.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Equip_DevCatalog',
        name: 'Equip_DevCatalog',
        component: () => import('@/views/equip/equip/Equip_DevCatalog.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_WorkShop',
        name: 'Base_WorkShop',
        component: () => import('@/views/custom/custom/Base_WorkShop.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Equip_Device',
        name: 'Equip_Device',
        component: () => import('@/views/equip/equip/Equip_Device.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Equip_DeviceTree',
        name: 'Equip_DeviceTree',
        component: () => import('@/views/equip/equip/Equip_DeviceTree.vue'),
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/DashBoard/WorkShopBoard',
        name: 'WorkShopBoard',
        component: () => import('@/views/dashboard/WorkShopBoard.vue'),
    }, {
        path: '/Equip_SpotMaintenance',
        name: 'Equip_SpotMaintenance',
        component: () => import('@/views/equip/equip/Equip_SpotMaintenance.vue')
    }, {
        path: '/Equip_SpotMaintPlan',
        name: 'Equip_SpotMaintPlan',
        component: () => import('@/views/equip/equip/Equip_SpotMaintPlan.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Equip_SpotMaintPlanDevice',
        name: 'Equip_SpotMaintPlanDevice',
        component: () => import('@/views/equip/equip/Equip_SpotMaintPlanDevice.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Equip_SpotMaintPlanProject',
        name: 'Equip_SpotMaintPlanProject',
        component: () => import('@/views/equip/equip/Equip_SpotMaintPlanProject.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Equip_MaintainPaper',
        name: 'Equip_MaintainPaper',
        component: () => import('@/views/equip/equip/Equip_MaintainPaper.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Equip_SpotMaintWorkOrder',
        name: 'Equip_SpotMaintWorkOrder',
        component: () => import('@/views/equip/equip/Equip_SpotMaintWorkOrder.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_TeamMember',
        name: 'Cal_TeamMember',
        component: () => import('@/views/calendar/calendar/Cal_TeamMember.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_Team',
        name: 'Cal_Team',
        component: () => import('@/views/calendar/calendar/Cal_Team.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_PlanShift',
        name: 'Cal_PlanShift',
        component: () => import('@/views/calendar/calendar/Cal_PlanShift.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_PlanTeam',
        name: 'Cal_PlanTeam',
        component: () => import('@/views/calendar/calendar/Cal_PlanTeam.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_Plan',
        name: 'Cal_Plan',
        component: () => import('@/views/calendar/calendar/Cal_Plan.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_TeamShift',
        name: 'Cal_TeamShift',
        component: () => import('@/views/calendar/calendar/Cal_TeamShift.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_HolidaySec',
        name: 'Cal_HolidaySec',
        component: () => import('@/views/calendar/calendar/Cal_HolidaySec.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Cal_Calendar',
        name: 'Cal_Calendar',
        component: () => import('@/views/calendar/calendar/calendar/index.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_ToolType',
        name: 'Tools_ToolType',
        component: () => import('@/views/tools/tools/Tools_ToolType.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_Tool',
        name: 'Tools_Tool',
        component: () => import('@/views/tools/tools/Tools_Tool.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_ToolsReceiveList',
        name: 'Tools_ToolsReceiveList',
        component: () => import('@/views/tools/tools/Tools_ToolsReceiveList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_ToolsReceive',
        name: 'Tools_ToolsReceive',
        component: () => import('@/views/tools/tools/Tools_ToolsReceive.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_ToolsReturnList',
        name: 'Tools_ToolsReturnList',
        component: () => import('@/views/tools/tools/Tools_ToolsReturnList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_ToolsReturn',
        name: 'Tools_ToolsReturn',
        component: () => import('@/views/tools/tools/Tools_ToolsReturn.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_Defect',
        name: 'Quality_Defect',
        component: () => import('@/views/quality/quality/Quality_Defect.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_TestItem',
        name: 'Quality_TestItem',
        component: () => import('@/views/quality/quality/Quality_TestItem.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_Template',
        name: 'Quality_Template',
        component: () => import('@/views/quality/quality/Quality_Template.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_TemplateTestItem',
        name: 'Quality_TemplateTestItem',
        component: () => import('@/views/quality/quality/Quality_TemplateTestItem.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_TemplateProduct',
        name: 'Quality_TemplateProduct',
        component: () => import('@/views/quality/quality/Quality_TemplateProduct.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_InComingCheckTestItem',
        name: 'Quality_InComingCheckTestItem',
        component: () => import('@/views/quality/quality/Quality_InComingCheckTestItem.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_InComingCheck',
        name: 'Quality_InComingCheck',
        component: () => import('@/views/quality/quality/Quality_InComingCheck.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_ProcessCheckTestItem',
        name: 'Quality_ProcessCheckTestItem',
        component: () => import('@/views/quality/quality/Quality_ProcessCheckTestItem.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_ProcessCheck',
        name: 'Quality_ProcessCheck',
        component: () => import('@/views/quality/quality/Quality_ProcessCheck.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_OutCheckTestItem',
        name: 'Quality_OutCheckTestItem',
        component: () => import('@/views/quality/quality/Quality_OutCheckTestItem.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Quality_OutCheck',
        name: 'Quality_OutCheck',
        component: () => import('@/views/quality/quality/Quality_OutCheck.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_ExcelTemplate',
        name: 'Base_ExcelTemplate',
        component: () => import('@/views/custom/custom/Base_ExcelTemplate.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_ExcelTemplateTree',
        name: 'Base_ExcelTemplateTree',
        component: () => import('@/views/custom/custom/Base_ExcelTemplateTree.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Company',
        name: 'Base_Company',
        component: () => import('@/views/custom/custom/Base_Company.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sales_SalesOrder',
        name: 'Sales_SalesOrder',
        component: () => import('@/views/sales/sales/Sales_SalesOrder.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sales_SalesOrderList',
        name: 'Sales_SalesOrderList',
        component: () => import('@/views/sales/sales/Sales_SalesOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Textures',
        name: 'Base_Textures',
        component: () => import('@/views/custom/custom/Base_Textures_Tree.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_Color',
        name: 'Base_Color',
        component: () => import('@/views/custom/custom/Base_Color.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Bi_db_dim',
        name: 'Bi_db_dim',
        component: () => import('@/views/bi/bimanage/Bi_db_dim.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Bi_db_source',
        name: 'Bi_db_source',
        component: () => import('@/views/bi/bimanage/Bi_db_source.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Bi_db_set',
        name: 'Bi_db_set',
        component: () => import('@/views/bi/bimanage/Bi_db_set.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Bi_db_ybp',
        name: 'Bi_db_ybp',
        component: () => import('@/views/bi/bimanage/Bi_db_ybp.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Bi_desktop',
        name: 'Bi_desktop',
        component: () => import('@/views/bi/bimanage/Bi_desktop.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_DesktopMenu',
        name: 'Base_DesktopMenu',
        component: () => import('@/views/custom/custom/Base_DesktopMenu.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_ToolsProductList',
        name: 'Tools_ToolsProductList',
        component: () => import('@/views/tools/tools/Tools_ToolsProductList.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Tools_ToolsProductList_ExtendData',
        name: 'Tools_ToolsProductList_ExtendData',
        component: () => import('@/views/tools/tools/Tools_ToolsProductList_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Production_WorkOrder_ExtendData',
        name: 'Production_WorkOrder_ExtendData',
        component: () => import('@/views/production/production/Production_WorkOrder_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sales_SalesOrder_ExtendData',
        name: 'Sales_SalesOrder_ExtendData',
        component: () => import('@/views/sales/sales/Sales_SalesOrder_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Sales_SalesOrderList_ExtendData',
        name: 'Sales_SalesOrderList_ExtendData',
        component: () => import('@/views/sales/sales/Sales_SalesOrderList_ExtendData.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_WorkOrderProductTool',
        name: 'View_WorkOrderProductTool',
        component: () => import('@/views/production/production/View_WorkOrderProductTool.vue')
    }    ,{
        path: '/Hr_Employee',
        name: 'Hr_Employee',
        component: () => import('@/views/human/human/Hr_Employee.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Sys_Config',
        name: 'Sys_Config',
        component: () => import('@/views/system/system/Sys_Config.vue'),        
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Base_WareHouse',
        name: 'Base_WareHouse',
        component: () => import('@/views/custom/custom/Base_WareHouse.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Purchase_RequestOrder',
        name: 'Purchase_RequestOrder',
        component: () => import('@/views/purchase/purchase/Purchase_RequestOrder.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Purchase_RequestOrderList',
        name: 'Purchase_RequestOrderList',
        component: () => import('@/views/purchase/purchase/Purchase_RequestOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Base_ProductType',
        name: 'Base_ProductType',
        component: () => import('@/views/custom/custom/Base_ProductType.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Purchase_PurchaseOrderList',
        name: 'Purchase_PurchaseOrderList',
        component: () => import('@/views/purchase/purchase/Purchase_PurchaseOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_RequestOrder',
        name: 'View_RequestOrder',
        component: () => import('@/views/purchase/purchase/View_RequestOrder.vue'),
    }    ,{
        path: '/Purchase_PurchaseOrder',
        name: 'Purchase_PurchaseOrder',
        component: () => import('@/views/purchase/purchase/Purchase_PurchaseOrder.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Ware_Lot',
        name: 'Ware_Lot',
        component: () => import('@/views/warehouse/warehouse/Ware_Lot.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_PurchasedProcessStatus',
        name: 'View_PurchasedProcessStatus',
        component: () => import('@/views/purchase/purchase/View_PurchasedProcessStatus.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Purchase_ChangeOrder',
        name: 'Purchase_ChangeOrder',
        component: () => import('@/views/purchase/purchase/Purchase_ChangeOrder.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Purchase_ChangeOrderList',
        name: 'Purchase_ChangeOrderList',
        component: () => import('@/views/purchase/purchase/Purchase_ChangeOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Sales_NoticeOrderList',
        name: 'Sales_NoticeOrderList',
        component: () => import('@/views/sales/sales/Sales_NoticeOrderList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Sales_NoticeOrder',
        name: 'Sales_NoticeOrder',
        component: () => import('@/views/sales/sales/Sales_NoticeOrder.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_WorkOrderSOP',
        name: 'View_WorkOrderSOP',
        component: () => import('@/views/production/production/View_WorkOrderSOP.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_InStock',
        name: 'View_InStock',
        component: () => import('@/views/warehouse/warehouse/View_InStock.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Base_BOM',
        name: 'Base_BOM',
        component: () => import('@/views/custom/custom/Base_BOM.vue'),
        meta: {
            keepAlive: false
        }
    }    
    ,{
        path: '/Base_BOMTree',
        name: 'Base_BOMTree',
        component: () => import('@/views/custom/custom/Base_BOMTree.vue'),
        meta: {
            keepAlive: false
        }
    }    
    ,{
        path: '/Base_ProcessLineTemplate',
        name: 'Base_ProcessLineTemplate',
        component: () => import('@/views/custom/custom/Base_ProcessLineTemplate.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Base_ProcessLineTemplateList',
        name: 'Base_ProcessLineTemplateList',
        component: () => import('@/views/custom/custom/Base_ProcessLineTemplateList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_ProcessLineProduct',
        name: 'View_ProcessLineProduct',
        component: () => import('@/views/custom/custom/View_ProcessLineProduct.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Base_BOMImport',
        name: 'Base_BOMImport',
        component: () => import('@/views/custom/custom/Base_BOMImport.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_QuotationOrder',
        name: 'View_QuotationOrder',
        component: () => import('@/views/purchase/purchase/View_QuotationOrder.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Base_Wage',
        name: 'Base_Wage',
        component: () => import('@/views/custom/custom/Base_Wage.vue'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/Base_WageTree',
        name: 'Base_WageTree',
        component: () => import('@/views/custom/custom/Base_WageTree.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Base_BOMList',
        name: 'Base_BOMList',
        component: () => import('@/views/custom/custom/Base_BOMList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Production_WorkOrderMaterialList',
        name: 'Production_WorkOrderMaterialList',
        component: () => import('@/views/production/production/Production_WorkOrderMaterialList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Finance_PayableBill',
        name: 'Finance_PayableBill',
        component: () => import('@/views/finance/finance/Finance_PayableBill.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Finance_ReceivableBill',
        name: 'Finance_ReceivableBill',
        component: () => import('@/views/finance/finance/Finance_ReceivableBill.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Finance_PayableBillList',
        name: 'Finance_PayableBillList',
        component: () => import('@/views/finance/finance/Finance_PayableBillList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Finance_ReceivableBillList',
        name: 'Finance_ReceivableBillList',
        component: () => import('@/views/finance/finance/Finance_ReceivableBillList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/Quality_Exception',
        name: 'Quality_Exception',
        component: () => import('@/views/quality/quality/Quality_Exception.vue'),
        meta: {
            keepAlive: false
        }
    }      ,{
        path: '/View_Finance_PurchaseReconciliation',
        name: 'View_Finance_PurchaseReconciliation',
        component: () => import('@/views/finance/finance/View_Finance_PurchaseReconciliation.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Purchase_PurchaseOrder',
        name: 'View_Purchase_PurchaseOrder',
        component: () => import('@/views/purchase/purchase/View_Purchase_PurchaseOrder.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_IQC_Assessment',
        name: 'View_IQC_Assessment',
        component: () => import('@/views/quality/quality/View_IQC_Assessment.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_SalesNoticeOrder',
        name: 'View_SalesNoticeOrder',
        component: () => import('@/views/sales/sales/View_SalesNoticeOrder.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_SalesNoticeOrderOperation',
        name: 'View_SalesNoticeOrderOperation',
        component: () => import('@/views/sales/sales/View_SalesNoticeOrderOperation.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_SalesOutWareHouse',
        name: 'View_Ware_SalesOutWareHouse',
        component: () => import('@/views/warehouse/warehouse/View_Ware_SalesOutWareHouse.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_ProductionInWareHouse',
        name: 'View_Ware_ProductionInWareHouse',
        component: () => import('@/views/warehouse/warehouse/View_Ware_ProductionInWareHouse.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Production_ToWareHouseCreate',
        name: 'View_Production_ToWareHouseCreate',
        component: () => import('@/views/production/production/View_Production_ToWareHouseCreate.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Production_ToWareHouseStatus',
        name: 'View_Production_ToWareHouseStatus',
        component: () => import('@/views/production/production/View_Production_ToWareHouseStatus.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_SalesNoticeOrderStatus',
        name: 'View_SalesNoticeOrderStatus',
        component: () => import('@/views/sales/sales/View_SalesNoticeOrderStatus.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_SalesOrderClose',
        name: 'View_SalesOrderClose',
        component: () => import('@/views/sales/sales/View_SalesOrderClose.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Finance_SalesReconciliation',
        name: 'View_Finance_SalesReconciliation',
        component: () => import('@/views/finance/finance/View_Finance_SalesReconciliation.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Production_ReportingForWork',
        name: 'View_Production_ReportingForWork',
        component: () => import('@/views/production/production/View_Production_ReportingForWork.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Finance_Receivables',
        name: 'View_Finance_Receivables',
        component: () => import('@/views/finance/finance/View_Finance_Receivables.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Finance_AccountsPayable',
        name: 'View_Finance_AccountsPayable',
        component: () => import('@/views/finance/finance/View_Finance_AccountsPayable.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_InventoryManagement',
        name: 'View_Ware_InventoryManagement',
        component: () => import('@/views/warehouse/warehouse/View_Ware_InventoryManagement.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_SalesOrderStatus',
        name: 'View_SalesOrderStatus',
        component: () => import('@/views/sales/sales/View_SalesOrderStatus.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_SalesNoticeOrderStatus',
        name: 'View_Ware_SalesNoticeOrderStatus',
        component: () => import('@/views/warehouse/warehouse/View_Ware_SalesNoticeOrderStatus.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_ToWareHouseStatus',
        name: 'View_Ware_ToWareHouseStatus',
        component: () => import('@/views/warehouse/warehouse/View_Ware_ToWareHouseStatus.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Report_SalesReconciliationSum',
        name: 'View_Report_SalesReconciliationSum',
        component: () => import('@/views/report/report/View_Report_SalesReconciliationSum.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Report_PurchaseReconciliationSum',
        name: 'View_Report_PurchaseReconciliationSum',
        component: () => import('@/views/report/report/View_Report_PurchaseReconciliationSum.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Report_OqcStatus',
        name: 'View_Report_OqcStatus',
        component: () => import('@/views/report/report/View_Report_OqcStatus.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_SalesNoticeOrderCreate',
        name: 'View_Ware_SalesNoticeOrderCreate',
        component: () => import('@/views/warehouse/warehouse/View_Ware_SalesNoticeOrderCreate.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_SalesNoticeOrderCreate',
        name: 'View_SalesNoticeOrderCreate',
        component: () => import('@/views/sales/sales/View_SalesNoticeOrderCreate.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_DeliveryNote',
        name: 'View_Ware_DeliveryNote',
        component: () => import('@/views/warehouse/warehouse/View_Ware_DeliveryNote.vue'),
        meta: {
            keepAlive: false
        }
    }        ,{
        path: '/View_IQC_Assessment_Outsourcing',
        name: 'View_IQC_Assessment_Outsourcing',
        component: () => import('@/views/quality/quality/View_IQC_Assessment_Outsourcing.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_IQC_BadHandle',
        name: 'View_IQC_BadHandle',
        component: () => import('@/views/quality/quality/View_IQC_BadHandle.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_IQC_BadHandleStatusList',
        name: 'View_IQC_BadHandleStatusList',
        component: () => import('@/views/quality/quality/View_IQC_BadHandleStatusList.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_IQC_BadHandle_Outsourcing',
        name: 'View_IQC_BadHandle_Outsourcing',
        component: () => import('@/views/quality/quality/View_IQC_BadHandle_Outsourcing.vue'),
        meta: {
            keepAlive: false
        }
    }    ,{
        path: '/View_Ware_IqcRepairInbound',
        name: 'View_Ware_IqcRepairInbound',
        component: () => import('@/views/warehouse/warehouse/View_Ware_IqcRepairInbound.vue'),
        meta: {
            keepAlive: false
        }
    }]

export default viewgird
